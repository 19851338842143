<template>
    <div>
        <div class="testPaperCenterSty">
            <!-- 单选 -->

            <div class="contentSty">
                <div class="cardTitle">
                    <div>
                        <i class="el-icon-s-flag"></i>
                        <span>
<!--						题型:{{questionBody.questionType | formatQuestionType }}-->
            试题编号:
					  </span>
                        <span>{{id}}</span>
                    </div>
                    <el-button type="primary" icon="el-icon-refresh-left" size="small" @click="toQuestionList">返回题库
                    </el-button>
                </div>
                <div class="one" style="margin-bottom: 10px">
                    <radioSubject v-if="(questionBody.questionType == 0) || (questionBody.questionType == 1) "
                                  @changed="changed" @unchanged="unchanged" :questionBody="questionBody">
                    </radioSubject>
                    <checkSubject v-if="questionBody.questionType == 2" :subjectId="questionBody.itemOrder"
                                  @changed="changed"
                                  @unchanged="unchanged" :questionBody="questionBody"></checkSubject>
                    <programmingSubject v-if="questionBody.questionType == 3" :subjectId="questionBody.itemOrder"
                                        @changed="changed"
                                        @unchanged="unchanged" :questionBody="questionBody">
                    </programmingSubject>
                    <programmingSubject v-if="questionBody.questionType == 4" :subjectId="questionBody.itemOrder"
                                        @changed="changed"
                                        @unchanged="unchanged" :questionBody="questionBody">
                    </programmingSubject>
                    <programmingSubjectMore v-if="questionBody.questionType == 5" :subjectId="questionBody.itemOrder"
                                            @changed="changed"
                                            @unchanged="unchanged" :questionBody="questionBody">
                    </programmingSubjectMore>
                    <div>
                        <!--            <el-button type="primary" style="float:left;margin-left: 350px;margin-top: 10px" @click="toQuestionList">返回</el-button>-->
                        <el-button type="error" style="margin-top: 20px;margin-bottom: 10px" @click="toQuestionList">取消</el-button>
                        <el-button type="primary" style="margin-top: 20px;margin-bottom: 10px" @click="submitAnswer">提交</el-button>

                    </div>
<!--                    <div style="background: #ffffff">-->
<!--                        <el-button type="primary" style="float:left;margin-top: 10px" @click="submitAnswer">上一题</el-button>-->
<!--                        <el-button type="primary" style="float:left;margin-top: 10px" @click="submitAnswer">下一题</el-button>-->
<!--                    </div>-->
                </div>
                <div style="width: 406px;margin-left: 2px;margin-bottom: 10px" class="two">
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            <span>相关属性</span>
                            <el-button style="float: right; padding: 3px 0" type="text" @click="toQuestionAnswer">答案解析
                            </el-button>
                        </div>
                        <div style="text-align: left;margin-left: 20px">
                            <span>作者: </span><span style="margin-left: 60px;">匿名</span><br>
                            <span>题型: </span><span style="margin-left: 60px">{{
                questionBody.questionType | formatQuestionType
              }}</span><br>
                            <span>代码限制: </span><span style="margin-left: 30px">100 KB</span><br>
                            <span>时间限制: </span><span style="margin-left: 30px">30 min</span><br>
                            <span>内存限制: </span><span style="margin-left: 30px">64 MB</span><br>
                            <span>发布时间: </span><span style="margin-left: 30px">2021-01-02</span>
                        </div>
                    </el-card>
                    <el-card class="box-card" style="margin-top: 10px">
                        <div id="myChart2" style="width:100%;height: 300px;padding-bottom: 15px">
                        </div>
                    </el-card>
                    <el-card class="box-card" style="margin-top: 10px;text-align: center">
                        <div slot="header" class="clearfix">
                            <span>讨论区</span>
                        </div>
                        暂未开放
                    </el-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import radioSubject from "@/components/radioSubject.vue"
    import checkSubject from "@/components/checkSubject.vue"
    import shortAnswerSubject from "@/components/shortAnswerSubject.vue"
    import programmingSubject from "@/components/programmingSubject.vue"
    import programmingSubjectMore from "@/components/programmingSubjectMore.vue"
    import questionAnswerDetail from "./questionAnswerDetail.vue"
    import {
        getQuestionDetail,
        submitQuestionAnswer
    } from "@/api/index.js"
    import * as echarts from "echarts";
    // 引入饼状图组件
    require('echarts/lib/chart/pie')
    // 引入提示框和title组件
    require('echarts/lib/component/tooltip')
    require('echarts/lib/component/title')

    export default {
        filters: {
            formatQuestionType(type) {
                switch (type) {
                    case 0:
                        return "单选题"
                        break;
                    case 1:
                        return "判断题"
                        break;
                    case 2:
                        return "多选题"
                        break;
                    case 3:
                        return "简答题"
                        break;
                    case 4:
                        return "编程题"
                        break;
                    case 5:
                        return "填空题"
                        break;
                    default:
                        break;
                }
            }
        },
        props: {
            questionDetail: {
                type: Object,
                default: () => ({})
            },
            subjectId: {
                type: Number,
                default: 1
            }
        },
        components: {
            radioSubject,
            checkSubject,
            shortAnswerSubject,
            programmingSubject,
            programmingSubjectMore,
            questionAnswerDetail
        },
        data() {
            return {
                id: null,
                value1: 4.5,
                questionReadVM: {},
                answerRequest: {},
                questionBody: {},
                dialogVisible: false,
                answer:null,
                answerArray:[],
            }
        },
        mounted() {
            this.id = this.$route.query.id
            getQuestionDetail(this.id).then(res => {
                if (res.code === "0000") {
                    this.questionBody = res.data
                    this.loading = false
                }
            }).catch(() => {
                this.loading = false
            });
            this.getPie()
        },
        methods: {
            //提交答案
            submitAnswer() {
                if ((this.answer == null && this.answerArray !=[])
                ||(this.answer != null && this.answerArray ==[])){
                    this.$message.error("填写答案后才能提交！")
                    return
                }

                this.$confirm('是否确定提交答案？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let param = {
                        questionId: this.id,
                        content: this.answer,
                        contentArray: this.answerArray
                    }
                    submitQuestionAnswer(param).then(res => {
                        if (res.code === "0000") {
                            var result =res.data
                            this.$confirm('本次答题结果: '+ result, '提示', {
                                confirmButtonText: '确定',
                                cancelButtonText: '答案解析',
                                type: 'warning'
                            }).then(() => {
                                this.$message({
                                    type: 'success',
                                    message: '提交成功!'
                                });
                            }).catch(() => {
                                this.$message({
                                    type: 'info',
                                    message: '答案解析',
                                    path:this.toQuestionAnswer()
                                });
                            });
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消提交'
                    });
                });
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {
                    });
            },
            changed(item) {
                this.answer=item.content
                this.answerArray = item.contentArray
                // if (this.isAnswer.length == 0) {
                //     this.isAnswer.push(item)
                // } else {
                //     let flag = 0
                //     // console.log(this.isAnswer)
                //     this.isAnswer.forEach((item1) => {
                //         if (item1.id == item.id) {
                //             flag = 1
                //             item1.content = item.content
                //             item1.contentArray = item.contentArray
                //         } else {
                //         }
                //     })
                //     if (flag == 0) {
                //         this.isAnswer.push(item)
                //     }
                // }
            },
            unchanged(item) {
                this.answer=item.content
                this.answerArray = item.contentArray
                // this.isAnswer.filter((item1, index) => {
                //     if (item1.id == item.id) {
                //         this.isAnswer.splice(index, 1)
                //     }
                // })
            },
            toQuestionList() {
                this.$router.go(-1);
                window.close()
            },
            //答案解析
            toQuestionAnswer() {
                this.$router.push({name: 'questionAnswerDetail', params: {id: this.id}});
            },
            getPie() {
                // 基于准备好的dom，初始化echarts实例
                var myChart = echarts.init(document.getElementById('myChart2'));
                // 绘制图表
                myChart.setOption({
                    title: {
                        text: '分析报告',//主标题
                        subtext: '暂未开放',//副标题
                        x: 'center',//x轴方向对齐方式
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: "{a} <br/>{b} : {c} ({d}%)"
                    },
                    legend: {
                        orient: 'vertical',
                        bottom: 'bottom',
                        data: ['提交数', '正确率', '平均耗时']
                    },
                    series: [
                        {
                            name: '访问来源',
                            type: 'pie',
                            radius: '55%',
                            center: ['50%', '60%'],
                            data: [
                                {value: 1000, name: '提交数'},
                                {value: 500, name: '正确率'},
                                {value: 500, name: '平均耗时'}
                            ],
                            itemStyle: {
                                emphasis: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                });
            }
        }


    }
</script>

<style lang="scss" scoped>
    .testPaperCenterSty {
        width: 1280px;
        margin: auto;
        margin-top: 20px;
        min-height: 300px;
        // background-color: #FFFFFF;
        position: relative;
        text-align: left;

        .cardTitle {
            background-color: #67C23A;
            height: 35px;
            padding-left: 20px;
            //padding-right: 5px;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: space-between;

            div:first-child {
                span {
                    line-height: 35px;
                    font-weight: 800;
                    color: #FFFFFF;
                }

                span:last-child {
                    margin-left: 10px;
                }
            }

            div:last-child {
                p {
                    padding: 0 5px;
                    line-height: 30px;
                    height: 30px;
                    background-color: #E6A23C;
                    font-weight: 800;
                }
            }


            .text {
                font-size: 14px;
            }

            .item {
                margin-bottom: 18px;
            }

            .clearfix:before,
            .clearfix:after {
                display: table;
                content: "";
            }

            .clearfix:after {
                clear: both
            }

            .box-card {
                width: 480px;
            }
        }

        .contentSty {
            padding-bottom: 30px 30px;
            margin-top: 20px;

            .box-card {
                text-align: left;
                padding: 0 30px;
                // margin-top: 10px;

                .title {
                    font-size: 18px;
                    font-weight: 800;
                    line-height: 30px;
                }

                .content {
                    font-size: 14px;
                    margin-top: 20px;
                    color: #808080;

                    span {
                        font-weight: 600;
                        color: #000;
                    }
                }
            }
        }
    }

    .one, .two {
        width: 68%;
        //height: 300px;
        float: left;
        box-sizing: border-box;
    }
</style>
